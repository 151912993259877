import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useToolStore = create((set) => ({
    tool: null,
    tools: [],
    fetchTools: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/tools`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ tools: response.data.tools });
        } catch (error) {
            console.error('Failed to fetch tools:', error);
        }
    },
    fetchToolById: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/tools/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            return response.data.tool;
        } catch (error) {
            console.error(`Failed to fetch tool with id ${id}:`, error);
            throw error;
        }
    },
    createTool: async (data) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/tools`, data, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({ tools: [...state.tools, response.data.tool] }));
            return response.data.tool;
        } catch (error) {
            console.error('Failed to create tool:', error);
            throw error;
        }
    },
    updateTool: async (id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/tools/${id}`, updatedData, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                tools: state.tools.map((tool) =>
                    tool.id === id ? response.data.tool : tool
                ),
            }));
            return response.data.tool;
        } catch (error) {
            console.error('Failed to update tool:', error);
            throw new Error(error.response.data.message);
        }
    },
    deleteTool: async (id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/tools/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                tools: state.tools.filter((tool) => tool.id !== id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete tool:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useToolStore;
