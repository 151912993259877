import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useToolOutputsStore = create((set) => ({
    toolOutputs: [],
    fetchAllToolOutputs: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/tool-outputs`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ toolOutputs: response.data.outputs });
        } catch (error) {
            console.error('Failed to fetch tool outputs:', error);
        }
    },
    createToolOutput: async (type, tool_id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/tool-outputs`,
                { tool_id, type },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({
                toolOutputs: [...state.toolOutputs, response.data.output],
            }));
            return response.data.output;
        } catch (error) {
            console.error('Failed to create tool output:', error);
            throw error;
        }
    },
    updateToolOutput: async (output_type_id, tool_id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/tool-outputs/${tool_id}/${output_type_id}`,
                updatedData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({
                toolOutputs: state.toolOutputs.map((toolOutput) =>
                    toolOutput.id === output_type_id ? response.data.output : toolOutput
                ),
            }));
            return response.data.output;
        } catch (error) {
            console.error('Failed to update tool output:', error);
            throw new Error(error.response.data.message);
        }
    },
    deleteToolOutput: async (tool_id, output_type_id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/tool-outputs/${tool_id}/${output_type_id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                toolOutputs: state.toolOutputs.filter((toolOutput) => toolOutput.id !== output_type_id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete tool output:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useToolOutputsStore;