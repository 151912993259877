import { create } from 'zustand';
import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

const useToolInputsStore = create((set) => ({
    toolInputs: [],
    fetchAllToolInputs: async () => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.get(`${process.env.REACT_APP_ADMIN_API}/admin/tool-inputs`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set({ toolInputs: response.data.inputs });
        } catch (error) {
            console.error('Failed to fetch tool inputs:', error);
        }
    },
    createToolInput: async (type, format, tool_id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.post(`${process.env.REACT_APP_ADMIN_API}/admin/tool-inputs`,
                { tool_id, type, format },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({
                toolInputs: [...state.toolInputs, response.data.input],
            }));
            return response.data.input;
        } catch (error) {
            console.error('Failed to create tool input:', error);
            throw error;
        }
    },
    updateToolInput: async (input_type_id, tool_id, updatedData) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.patch(`${process.env.REACT_APP_ADMIN_API}/admin/tool-inputs/${tool_id}/${input_type_id}`,
                updatedData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken.toString()}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            set((state) => ({
                toolInputs: state.toolInputs.map((toolInput) =>
                    toolInput.id === input_type_id ? response.data.input : toolInput
                ),
            }));
            return response.data.input;
        } catch (error) {
            console.error('Failed to update tool input:', error);
            throw new Error(error.response.data.message);
        }
    },
    deleteToolInput: async (tool_id, input_type_id) => {
        try {
            const { accessToken } = (await fetchAuthSession()).tokens ?? {};
            const response = await axios.delete(`${process.env.REACT_APP_ADMIN_API}/admin/tool-inputs/${tool_id}/${input_type_id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken.toString()}`,
                    'Content-Type': 'application/json',
                },
            });
            set((state) => ({
                toolInputs: state.toolInputs.filter((toolInput) => toolInput.id !== input_type_id),
            }));
            return response.data.message;
        } catch (error) {
            console.error('Failed to delete tool input:', error);
            throw new Error(error.response.data.message);
        }
    },
}));

export default useToolInputsStore;