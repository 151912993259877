import React from 'react';
import { signOut } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../store/user-store'; // Adjust the import path as necessary

const Navbar = () => {
  const navigate = useNavigate();
  const clearUser = useUserStore((state) => state.clearUser); // Use the clearUser function from the store

  const handleLogout = async () => {
    await signOut();
    localStorage.removeItem('user_id');
    clearUser(); // Clear the user state from the store
    navigate('/');
  };

  const navLinks = [
    { to: "/reports", label: "Reports" },
    { to: "/users", label: "Users" },
    { to: "/organisations", label: "Organisations" },
    { to: "/tools", label: "Tools" },
    { to: "/tool-fields", label: "Tool Fields" },
    { to: "/tool-inputs", label: "Tool Inputs" },
    { to: "/tool-outputs", label: "Tool Outputs" },
    { to: "/constants", label: "Constants" },
    { to: "/templates", label: "Templates" },
    { to: "/api-clients", label: "API Clients" },
    { to: "/api-runs", label: "API Runs" },
  ];

  return (
    <nav className="bg-gray-800">
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-white font-semibold text-xl">
              MPAssist
            </Link>
          </div>
          <div className="flex items-center space-x-2">
            {navLinks.map((link, index) => (
              <>
                <Link key={link.to} to={link.to} className="text-gray-300 hover:text-white">
                  {link.label}
                </Link>
                <span key={index} className='text-gray-500'>|</span>
              </>
            ))}
            <button onClick={handleLogout} className="text-gray-300 hover:text-white">
              Logout
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
